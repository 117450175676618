import React, { useState } from "react"
import { ButtonPrimary } from "./Button"
import TextInput from "./TextInput"

export function SearchForm({
	defaultValue,
	onSearch,
	placeholder,
	loading,
}: {
	defaultValue: string
	onSearch: (searchTerm: string) => void
	placeholder: string
	loading: boolean
}) {
	const [searchTerm, setSearchTerm] = useState(defaultValue)
	return (
		<>
			<TextInput
				value={searchTerm}
				onChangeText={setSearchTerm}
				placeholder={placeholder}
				onSubmitEditing={() => onSearch(searchTerm)}
				blurOnSubmit={false}
			/>
			<ButtonPrimary
				disabled={loading}
				title={loading ? "Loading" : "Search"}
				onPress={() => onSearch(searchTerm)}
			/>
		</>
	)
}
