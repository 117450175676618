import { useApolloClient, useMutation } from "@apollo/react-hooks"
import React, { useEffect, useState } from "react"
import { StyleSheet, Text, TextStyle } from "react-native"
import { useHistory } from "react-router"
import Box from "../components/Box"
import { ButtonPrimary } from "../components/Button"
import Form from "../components/Form"
import { Loading } from "../components/Loading"
import Panel from "../components/Panel"
import QueryError from "../components/QueryError"
import {
	ScreenContainerBasicNarrow,
	NarrowContainer,
} from "../components/ScreenContainer"
import TextInput from "../components/TextInput"
import ValidationErrors from "../components/ValidationErrors"
import {
	createSessionTokenBearer,
	createSessionTokenBearerVariables,
} from "../generated/types/createSessionTokenBearer"
import {
	createSessionTokenCookie,
	createSessionTokenCookieVariables,
} from "../generated/types/createSessionTokenCookie"
import useDocumentTitle from "../hooks/useDocumentTitle"
import { useSession } from "../hooks/useSession"
import { useViewer } from "../hooks/useViewer"
import {
	createSessionTokenBearerMutation,
	createSessionTokenCookieMutation,
} from "../queries/sessionToken.query"
import viewerQuery from "../queries/viewer.query"
import { forgotPasswordPath, rootPath } from "../routing/paths"
import { Link, Redirect } from "../routing/Router"

const LoginScreen = () => {
	useDocumentTitle("Login")

	const history = useHistory()
	const viewerRequest = useViewer()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [isComplete, setIsComplete] = useState(false)

	const session = useSession()
	const client = useApolloClient()

	const mutation = (() => {
		switch (session.authenticationStrategy) {
			case "bearer":
				return createSessionTokenBearerMutation
			case "cookies":
				return createSessionTokenCookieMutation
			default:
				throw new Error("invariant: unexpected authentication strategy")
		}
	})()

	const [createSessionToken, { data, error, loading }] = useMutation<
		createSessionTokenBearer | createSessionTokenCookie,
		createSessionTokenBearerVariables | createSessionTokenCookieVariables
	>(mutation, {
		variables: {
			input: {
				email,
				password,
				cookie: session.authenticationStrategy === "cookies",
			},
		},
		refetchQueries: [{ query: viewerQuery }],
	})

	useEffect(() => {
		if (!data) return

		if (session.authenticationStrategy === "bearer") {
			if ("sessionToken" in data.createSessionToken) {
				const { sessionToken } = data.createSessionToken

				if (sessionToken && sessionToken.id) {
					session.setSessionToken(sessionToken.id)
				}
			}
			return
		}

		if (session.authenticationStrategy === "cookies") {
			if (data.createSessionToken.errors.length === 0) {
				setIsComplete(true)
			}
			return
		}
	}, [client, data, history, session])

	if (viewerRequest.viewer) {
		return <Redirect to={rootPath()} />
	}

	if (viewerRequest.loading) {
		return <Loading />
	}

	const handleSubmit = () => createSessionToken()
	return (
		<ScreenContainerBasicNarrow>
			<NarrowContainer>
				<Panel>
					<Form>
						<Box mb={2}>
							<Text>Email</Text>
							<TextInput
								value={email}
								onChangeText={setEmail}
								onSubmitEditing={handleSubmit}
								autoFocus={true}
								textContentType="emailAddress"
								keyboardType="email-address"
								autoCapitalize="none"
								blurOnSubmit={false}
								// autoCompleteType="username"
							/>
						</Box>
						<Box mb={2}>
							<Text>Password</Text>
							<TextInput
								value={password}
								onChangeText={setPassword}
								onSubmitEditing={handleSubmit}
								secureTextEntry={true}
								blurOnSubmit={false}
								autoCompleteType="password"
								autoCorrect={false}
								autoCapitalize="none"
							/>
						</Box>

						{data && data.createSessionToken.errors.length ? (
							<Box mb={2}>
								<ValidationErrors
									errors={data.createSessionToken.errors}
									path="password"
								/>
							</Box>
						) : null}

						<Box mb={3}>
							<ButtonPrimary
								title={isComplete ? "Redirecting…" : "Login"}
								onPress={handleSubmit}
								disabled={loading || isComplete}
							/>
						</Box>
					</Form>

					<Link to={forgotPasswordPath()}>
						<Text style={styles.centered}>Forgot password</Text>
					</Link>
				</Panel>
				{error ? <QueryError error={error} /> : null}
			</NarrowContainer>
		</ScreenContainerBasicNarrow>
	)
}

const styles = StyleSheet.create({
	centered: {
		textAlign: "center",
	} as TextStyle,
})

export default LoginScreen
