/**
 * Pluralize a word
 *
 * ```
 * pluralize("dog", 7)        // "dogs"
 * pluralize("dog", 7,  true) // "7 dogs"
 * ```
 *
 * lightweight verison of https://github.com/blakeembrey/pluralize
 *
 * @param singular  the singular form of the word to pluralize
 * @param count  how many
 * @returns pluralized version of a word when value is not 1
 */
export default function pluralize(
	singular: string,
	count: number,
	showCount = false
) {
	const result = count === 1 ? singular : `${singular}s`
	return showCount ? `${count} ${result}` : result
}
