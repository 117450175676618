import { useQuery } from "@apollo/react-hooks"
import React, { useState } from "react"
import { Container } from "../components/Container"
import { RecipeCardWithoutUser } from "../components/RecipeCard"
import RecipeList from "../components/RecipeList"
import ScreenContainer from "../components/ScreenContainer"
import { SearchForm } from "../components/SearchForm"
import { View } from "../components/Styled"
import { homeScreen, homeScreenVariables } from "../generated/types/homeScreen"
import useAuthenticatedRoute from "../hooks/useAuthenticatedRoute"
import useDocumentTitle from "../hooks/useDocumentTitle"
import { homeScreenQuery } from "../queries/homeScreen.query"
import { homeScreenPath } from "../routing/paths"
import { mergeResults } from "../util/mergeResults"
import { useScrollContextOnPop } from "../util/ScrollContextHelper"

export default function HomeScreen() {
	useDocumentTitle("Braise", "")
	useAuthenticatedRoute()

	const [query, setQuery] = useState("")
	const variables = {
		...(query && { query }),
	}

	const { data, loading, error, fetchMore, refetch } = useQuery<
		homeScreen,
		homeScreenVariables
	>(homeScreenQuery, {
		fetchPolicy: "cache-first",
		variables,
		notifyOnNetworkStatusChange: true,
	})

	if (error) throw error

	const scrollContext = useScrollContextOnPop(homeScreenPath()) // TODO should handle search?
	const recipes = (data && data.viewer && data.viewer.recipes) || null

	return (
		<ScreenContainer>
			{data && (
				<Container>
					<View
						sx={{
							bg: "background",
							p: 3,
							borderBottomColor: "silver",
							borderBottomWidth: 1,
						}}
					>
						<SearchForm
							defaultValue={query}
							onSearch={(value) => {
								query !== value ? setQuery(value) : refetch()
							}}
							placeholder="Search recipes"
							loading={loading}
						/>
					</View>
				</Container>
			)}
			<RecipeList
				RecipeCardComponent={RecipeCardWithoutUser}
				recipes={recipes}
				isFetching={loading}
				onLoadMore={() => {
					if (loading) return
					if (!recipes) return
					if (!recipes.pageInfo.hasNextPage) return

					fetchMore({
						variables: {
							...variables,
							after: recipes.pageInfo.endCursor,
						},
						updateQuery: (prev, { fetchMoreResult }) =>
							mergeResults(prev, fetchMoreResult),
					})
				}}
				contextProvider={scrollContext}
			/>
		</ScreenContainer>
	)
}
