import React, { ReactNode } from "react"
import { SafeAreaView, StyleSheet, ViewStyle } from "react-native"
import { colors, widths } from "../theme"
import { View } from "./Styled"

interface Props {
	children: ReactNode
}

const ScreenContainer = ({ children }: Props) => (
	<View style={styles.Container} testID={"ScreenContainer"}>
		<View>{children}</View>
	</View>
)

export const NarrowContainer = ({
	children,
}: {
	/** @deprecated */
	style?: ViewStyle
	children?: React.ReactNode
}) => (
	<View
		sx={{
			width: "100%",
			maxWidth: widths.narrow,
			ml: "auto",
			mr: "auto",
		}}
	>
		{children}
	</View>
)

export const NormalContainer = ({
	children,
}: {
	children: React.ReactNode
}) => (
	<View style={styles.fullWidth}>
		<View style={styles.normal}>{children}</View>
	</View>
)

export const ScreenContainerBasicNarrow: React.FunctionComponent = ({
	children,
}) => <SafeAreaView>{children}</SafeAreaView>

const styles = StyleSheet.create({
	Container: {
		width: "100%",
		flex: 1,
		backgroundColor: colors.silver,
		minHeight: "100%",
	} as ViewStyle,
	normal: {
		width: "100%",
		maxWidth: widths.normal,
		flex: 1,
	} as ViewStyle,
	narrow: {
		width: "100%",
		maxWidth: widths.narrow,
		flex: 1,
	} as ViewStyle,
	fullWidth: {
		width: "100%",
		alignItems: "center",
		// backgroundColor: colors.silver,
	} as ViewStyle,
})

export default ScreenContainer
