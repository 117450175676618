import { useTheme } from "@emotion/react"
import React from "react"
import { colors } from "../theme"
import { Button, TouchableOpacity, Text } from "./Styled"

export const ButtonPrimary = (props: React.ComponentProps<typeof Button>) => {
	const theme = useTheme() as any
	const primary = theme?.colors?.primary || "#ff0000"
	return <Button color={primary} {...props} />
}

export const ButtonPrimary2 = ({
	children,
	title,
	...props
}: React.ComponentProps<typeof TouchableOpacity> & { title?: string }) => {
	return (
		<TouchableOpacity
			{...props}
			sx={{
				bg: props.disabled ? "muted" : "primary",
				alignItems: "center",
				flexDirection: "row",
				pl: 2,
				pr: 2,
				pt: 1,
				pb: 1,
				borderRadius: 2,
				...props.sx,
			}}
		>
			{title ? (
				<Text sx={{ color: "background" }}>{title.toUpperCase()}</Text>
			) : (
				children
			)}
		</TouchableOpacity>
	)
}

export const ButtonOutline = (props: React.ComponentProps<typeof Button>) => (
	<Button color={colors.black} {...props} />
)
