import React from "react"
import { StyleSheet, View } from "react-native"
import Image from "../components/Image"
import { RecipeCardFragment } from "../generated/types/RecipeCardFragment"
import { recipePath, userPath } from "../routing/paths"
import { Link } from "../routing/Router"
import { colors, space } from "../theme"
import pluralize from "../util/pluralize"
import Separator from "./Separater"
import { Text } from "./Styled"
import Time from "./Time"
import UrlLink from "./UrlLink"

const Card: React.FC = (props) => <View style={styles.RecipeCard} {...props} />
const Body: React.FC = (props) => (
	<View style={styles.RecipeCardBody} {...props} />
)

const height = 128

const RecipeDetails = ({ recipe }: { recipe: RecipeCardFragment }) => (
	<>
		<Link to={recipePath(recipe)}>
			<Text
				sx={{ color: "primary", fontSize: 2, lineHeight: 21, mb: 1 }}
				numberOfLines={2}
			>
				{recipe.displayName}
			</Text>
		</Link>

		{recipe.url ? (
			<UrlLink
				urlVerbatim={recipe.urlVerbatim}
				url={recipe.url}
				textStyle={styles.urlLink}
			/>
		) : null}

		<View style={{ flexDirection: "row" }}>
			<Time time={recipe.createdAt} />
			{recipe.ingredientCount ? (
				<>
					<Separator />
					<Text>{pluralize("Ingredient", recipe.ingredientCount, true)}</Text>
				</>
			) : null}
		</View>
	</>
)

const RecipeImage = ({ recipe }: { recipe: RecipeCardFragment }) => {
	const image = recipe.images.length > 0 ? recipe.images[0] : undefined
	if (!image) return null

	return (
		<View style={styles.RecipeCardImage}>
			<Link
				to={recipePath(recipe)}
				accessible={false}
				importantForAccessibility="no-hide-descendants"
			>
				<View style={{ width: "100%" }} testID="xxx">
					<Image
						image={image}
						accessible={true}
						resizeMode="cover"
						border={false}
					/>
				</View>
			</Link>
		</View>
	)
}

export const RecipeCardWithUser = ({
	recipe,
}: {
	recipe: RecipeCardFragment
}) => (
	<Card>
		<Body>
			<View style={styles.UserLinkBlock}>
				<Link to={userPath(recipe.user)}>
					<Text style={styles.UserLinkText}>{recipe.user.handle}</Text>
				</Link>
			</View>

			<RecipeDetails recipe={recipe} />
		</Body>

		<RecipeImage recipe={recipe} />
	</Card>
)
RecipeCardWithUser.height = height

export const RecipeCardWithoutUser = ({
	recipe,
}: {
	recipe: RecipeCardFragment
}) => (
	<Card>
		<Body>
			<RecipeDetails recipe={recipe} />
		</Body>
		<RecipeImage recipe={recipe} />
	</Card>
)
RecipeCardWithoutUser.height = height

const styles = StyleSheet.create({
	RecipeHandleAction: {
		marginBottom: space[2],
	},
	RecipeTitle: {
		color: colors.primary,
		marginBottom: space[2],
	},
	RecipeCard: {
		flexDirection: "row",
		backgroundColor: colors.white,
	},
	RecipeCardImage: {
		width: height,
		height: height,
	},
	RecipeCardBody: {
		flex: 1,
		paddingTop: space[2],
		paddingBottom: space[2],
		paddingLeft: space[2],
		paddingRight: space[2],
		height: height,
	},
	UserLinkBlock: {
		marginBottom: space[2],
	},
	UserLinkText: {},
	urlLink: {
		marginBottom: space[2],
	},
})
