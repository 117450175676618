import * as colors from "./theme/colors"
import * as fontWeights from "./theme/fontWeights"

export const space: [0, 4, 8, 16, 32] = [0, 4, 8, 16, 32]

const widths = {
	narrow: 600,
	normal: 900,
}

export { colors, fontWeights, widths }

export const theme = {
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	fonts: {
		body:
			'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
		heading: "inherit",
		monospace: "Menlo, monospace",
	},
	fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
	fontWeights: {
		body: "400",
		heading: "700",
		bold: "600",
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	colors: {
		text: "#000",
		background: "#ffffff",
		primary: "hsla(41, 95%, 45%, 1)",
		secondary: "#30c",
		muted: "#f6f6f6",
		silver: "#dddddd",

		// highlight: 'hsl(10, 40%, 90%)',
		// purple: 'hsl(250, 60%, 30%)',
		// gray: 'hsl(10, 20%, 50%)',
	},
	sizes: {
		container: 600,
	},
}
