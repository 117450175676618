// https://github.com/ReactTraining/react-router/issues/5437
// https://github.com/ReactTraining/react-router/blob/v4.3.1/packages/react-router-dom/modules/Link.js
import React from "react"
import { Linking, Platform, TouchableHighlightProps } from "react-native"
import { TouchableOpacity } from "./Scrollable"

export interface ExternalLinkProps extends TouchableHighlightProps {
	href: string
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
	href,
	target = "_blank",
	...rest
}) => (
	<TouchableOpacity
		href={href}
		accessibilityRole="link"
		target={target}
		onPress={() => {
			if (Platform.OS === "web") return
			Linking.openURL(href)
		}}
		{...rest}
	/>
)
