import "core-js"
import "regenerator-runtime/runtime"
import React from "react"
import { AppRegistry } from "react-native"
import Braise from "@braise/core"

const App = () => <Braise auth="cookies" uri="/graphql" />

AppRegistry.registerComponent("App", () => App)
AppRegistry.runApplication("App", {
	rootTag: document.getElementById("react-root"),
})

const placeholder = document.getElementById("nav-placeholder")
if (placeholder instanceof HTMLDivElement) {
	placeholder.remove()
} else {
	console.info("Expected to remove placeholder, none found")
}
