import React from "react"
import { StyleSheet, Text, TextStyle } from "react-native"
import { ValidationErrorFragment } from "../generated/types/ValidationErrorFragment"
import { colors } from "../theme"

interface ValidationErrorProps {
	path?: string
	errors: ValidationErrorFragment[]
}

const ValidationErrors = ({ path, errors }: ValidationErrorProps) => {
	const filteredErrors = path
		? errors.filter((error) => error.path === path)
		: errors
	if (filteredErrors.length === 0) return null

	return (
		<>
			{filteredErrors.map((error, i) => (
				<Text key={i} style={styles.text}>
					{path ? null : `${error.path} `}
					{error.message}
				</Text>
			))}
		</>
	)
}

interface Styles {
	text: TextStyle
}

const styles = StyleSheet.create<Styles>({
	text: {
		color: colors.red,
	},
})

export default ValidationErrors
