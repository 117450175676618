/**
 * @todo type this?
 */
export function mergeResults(previous: any, current: any): any {
	const isObject =
		current && typeof current === "object" && current.constructor === Object

	if (!isObject) {
		return current
	}

	return Object.keys(current).reduce(
		(acc, property) => ({
			...acc,
			[property]:
				property === "edges" || property === "nodes"
					? [...previous[property], ...current[property]]
					: mergeResults(previous[property], current[property]),
		}),
		{}
	)
}
