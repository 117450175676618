interface Recipe {
	slug: string
	user: { handle: string }
}

interface WithId {
	id: string
}

interface WithHandle {
	handle: string
}

const simpleRoute = (path: string) => () => path

export const recipePath = (recipe: Recipe) =>
	`/${recipe.user.handle}/${recipe.slug}`

export const recipeEditPath = (recipe: Recipe) => `${recipePath(recipe)}/edit`

export const userPath = ({ handle }: WithHandle) => `/${handle}`
export const userFollowersPath = ({ handle }: WithHandle) =>
	`/${handle}/followers`
export const userFollowingPath = ({ handle }: WithHandle) =>
	`/${handle}/following`

export const rootPath = simpleRoute("/")
export const homeScreenPath = rootPath
export const settingsPath = simpleRoute("/settings")
export const bookmarkNewPath = simpleRoute("/bookmarks/new")
export const friendsPath = simpleRoute("/friends")
export const searchPath = simpleRoute("/search")
export const loginPath = simpleRoute("/login")
export const forgotPasswordPath = simpleRoute("/password-reset")

export const passwordUpdatePath = ({ token }: { token: string }) =>
	`/password-reset/${token}`

export const recipeImagePath = (recipe: Recipe, image: WithId) =>
	`${recipePath(recipe)}/images/${image.id}`

export const cookingNoteImagePath = (cookingNote: WithId, image: WithId) =>
	`/${cookingNote.id}/images/${image.id}`

export const exampleErrorPath = simpleRoute(`/example-error`)
