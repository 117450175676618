import React, { useMemo } from "react"
import { ActivityIndicator } from "react-native"
import {
	DataProvider,
	LayoutProvider,
	RecyclerListView,
} from "recyclerlistview"
import {
	RecipeCardWithoutUser,
	RecipeCardWithUser,
} from "../components/RecipeCard"
import {
	RecipeList as RecipeListType,
	RecipeList_nodes,
} from "../generated/types/RecipeList"
import { useDimensionsBasic } from "../hooks/useDimensions"
import { ScrollContextHelper } from "../util/ScrollContextHelper"
import { isNotNullish } from "../util/typeGuards"
import { Loading } from "./Loading"
import { NarrowContainer } from "./ScreenContainer"
import { Scrollable } from "./Scrollable"
import { View } from "./Styled"

const RecipeList = ({
	recipes,
	isFetching,
	onLoadMore,
	contextProvider: scrollContext,
	RecipeCardComponent = RecipeCardWithUser,
}: {
	recipes: RecipeListType | null
	isFetching: boolean
	onLoadMore: () => void
	contextProvider: ScrollContextHelper
	RecipeCardComponent?: typeof RecipeCardWithUser | typeof RecipeCardWithoutUser
}) => {
	const { width } = useDimensionsBasic()

	const nodes = useMemo(() => {
		return recipes ? (recipes.nodes || []).filter(isNotNullish) : []
	}, [recipes])

	const dataProvider = useMemo(
		() =>
			new DataProvider(
				(r1: RecipeList_nodes, r2: RecipeList_nodes) => r1.id !== r2.id
			).cloneWithRows(nodes),
		[nodes]
	)

	const layoutProvider = useMemo(
		() =>
			new LayoutProvider(
				() => 0,
				(_, dim) => {
					dim.width = width
					dim.height = RecipeCardComponent.height + 1
				}
			),
		[width, RecipeCardComponent]
	)

	if (nodes.length === 0) {
		if (isFetching)
			return (
				<View sx={{ p: 3 }}>
					<ActivityIndicator />
				</View>
			)
		return null
	}

	return (
		<Scrollable>
			<RecyclerListView
				canChangeSize={true}
				useWindowScroll={true}
				layoutProvider={layoutProvider}
				dataProvider={dataProvider}
				rowRenderer={(_, data) => (
					<NarrowContainer>
						<RecipeCardComponent recipe={data} />
					</NarrowContainer>
				)}
				onEndReached={onLoadMore}
				renderFooter={() => <Loading isPlaceholder={!isFetching} />}
				contextProvider={scrollContext}
			/>
		</Scrollable>
	)
}

export default React.memo(RecipeList)
