import React from "react"
import { View, StyleSheet, ViewStyle } from "react-native"
import { colors, space } from "../theme"

interface Props {
	children: React.ReactNode
	style?: ViewStyle
}

const Panel = (props: Props) => (
	<View {...props} style={[props.style, styles.View]} />
)

const styles = StyleSheet.create<{ [x: string]: ViewStyle }>({
	View: {
		backgroundColor: colors.white,
		// borderRadius: space[1],
		padding: space[3],
		borderColor: colors.silver,
		borderBottomWidth: StyleSheet.hairlineWidth,
		borderStyle: "solid",
	},
})

export default Panel
