import React, { useState } from "react"
import { Image as RNImage, ImageSourcePropType, StyleSheet } from "react-native"
import { imageUrl } from "../util/image"
import AspectRatio from "./AspectRatio"
import { Image as ImageType } from "../generated/types/Image"

export interface ImageProps {
	image: ImageType
	accessible: boolean
	border?: boolean
	resizeMode?: "cover" | "contain" | "stretch" | "repeat" | "center" | undefined
}

const Image = ({
	image,
	accessible,
	resizeMode = "contain",
	border = true,
}: ImageProps) => {
	const scaledWidth = 1200

	const [bgColor] = useState(randomColor())

	let source: ImageSourcePropType = {
		uri: imageUrl({ image, width: scaledWidth }),
	}

	// let aspectRatio =
	//   image.width && image.height ? image.width / image.height : undefined
	// if (aspectRatio) {
	//   source.width = scaledWidth
	//   source.height = scaledWidth * (1/aspectRatio)
	// }

	return (
		<AspectRatio ratio={1} style={{}}>
			<RNImage
				style={{
					flex: 1,
					borderWidth: border ? 10 : undefined,
					borderColor: bgColor,
					backgroundColor: bgColor,
					...StyleSheet.absoluteFillObject,
				}}
				resizeMode={resizeMode}
				source={source}
				accessible={accessible}
			/>
		</AspectRatio>
	)
}
const COLORS = ["#688144", "#babdb9", "#d3918a"]
const randomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)]

export default Image
