import React, { Suspense } from "react"
import { RouteProps } from "react-router"
import { LoadingScreen } from "../components/Loading"
import * as paths from "../routing/paths"
import { Route, Switch } from "../routing/Router"
import ErrorReporter from "./ErrorReporter"
import HomeScreen from "./HomeScreen"
import LoginScreen from "./LoginScreen"
import NavigationContainer, { NavigationBack } from "./NavigationContainer"

const FriendsScreen = React.lazy(() =>
	import(/* webpackPrefetch: true */ "./FriendsScreen")
)

const RecipeScreen = React.lazy(() =>
	import(/* webpackPrefetch: true */ "./RecipeScreen")
)

const NotFoundScreen = React.lazy(() => import("./NotFoundScreen"))
const PasswordResetScreen = React.lazy(() => import("./PasswordResetScreen"))
const PasswordUpdateScreen = React.lazy(() => import("./PasswordUpdateScreen"))
const ProfileRecipeListScreen = React.lazy(() =>
	import(/* webpackPrefetch: true */ "./ProfileRecipeListScreen")
)
const RecipeEditorScreen = React.lazy(() => import("./RecipeEditorScreen"))
const RecipeImageScreen = React.lazy(() => import("./RecipeImageScreen"))
const SearchScreen = React.lazy(() =>
	import(/* webpackPrefetch: true */ "./SearchScreen")
)
const CookingNoteImageScreen = React.lazy(() =>
	import("./CookingNoteImageScreen")
)
const FollowersScreen = React.lazy(() => import("./FollowersScreen"))
const FollowingScreen = React.lazy(() => import("./FollowingScreen"))
const NewBookmarkScreen = React.lazy(() =>
	import(/* webpackPrefetch: true */ "./NewBookmarkScreen")
)
const SettingsScreen = React.lazy(() => import("./SettingsScreen"))
const ExampleErrorScreen = React.lazy(() => import("./ExampleErrorScreen"))

const BasicRoute = ({ children, ...rest }: RouteProps) => (
	<Route {...rest}>
		<ErrorReporter>{children}</ErrorReporter>
	</Route>
)

const SuspenseRoute = ({ children, ...rest }: RouteProps) => (
	<Route {...rest}>
		<ErrorReporter>
			<Suspense fallback={<LoadingScreen />}>{children}</Suspense>
		</ErrorReporter>
	</Route>
)

export default function Routes() {
	return (
		<>
			<Switch>
				<BasicRoute
					path={[paths.rootPath(), paths.friendsPath(), paths.searchPath()]}
					exact={true}
				>
					<NavigationContainer />
				</BasicRoute>
				<BasicRoute path={[paths.loginPath()]} />
				<BasicRoute>
					<NavigationBack />
				</BasicRoute>
			</Switch>

			<Switch>
				<BasicRoute exact path={paths.loginPath()}>
					<LoginScreen />
				</BasicRoute>

				<BasicRoute exact path={paths.rootPath()}>
					<HomeScreen />
				</BasicRoute>

				<SuspenseRoute exact path={paths.friendsPath()}>
					<FriendsScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.searchPath()}>
					<SearchScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.bookmarkNewPath()}>
					<NewBookmarkScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.settingsPath()}>
					<SettingsScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.forgotPasswordPath()}>
					<PasswordResetScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.exampleErrorPath()}>
					<ExampleErrorScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.passwordUpdatePath({ token: ":token" })}
				>
					<PasswordUpdateScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.userFollowersPath({ handle: ":userId" })}
				>
					<FollowersScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.userFollowingPath({ handle: ":userId" })}
				>
					<FollowingScreen />
				</SuspenseRoute>

				<SuspenseRoute exact path={paths.userPath({ handle: ":userId" })}>
					<ProfileRecipeListScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.recipeEditPath({
						user: { handle: ":userId" },
						slug: ":recipeSlug",
					})}
				>
					<RecipeEditorScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.recipePath({
						user: { handle: ":userId" },
						slug: ":recipeSlug",
					})}
				>
					<RecipeScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.recipeImagePath(
						{
							user: { handle: ":userId" },
							slug: ":recipeSlug",
						},
						{ id: ":imageId" }
					)}
				>
					<RecipeImageScreen />
				</SuspenseRoute>

				<SuspenseRoute
					exact
					path={paths.cookingNoteImagePath(
						{ id: ":cookingNoteId" },
						{ id: ":imageId" }
					)}
				>
					<CookingNoteImageScreen />
				</SuspenseRoute>

				<SuspenseRoute path="*" exact={true}>
					<NotFoundScreen />
				</SuspenseRoute>
			</Switch>
		</>
	)
}
