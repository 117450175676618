import * as Sentry from "@sentry/browser"
import React, { useState } from "react"
import { isApolloError } from "apollo-client"
import ReactErrorBoundary, { FallbackProps } from "react-error-boundary"
import { Text, View, SafeAreaView } from "../components/Styled"
import { ButtonOutline } from "../components/Button"

const IGNORED_APOLLO_NETWORK_ERRORS = ["Failed to fetch", "cancelled"]

const myErrorHandler = (error: Error, _componentStack: string) => {
	if (!isErrorExpected(error)) {
		Sentry.captureException(error)
	}
}

function isErrorExpected(error: unknown): boolean {
	if (!(error instanceof Error)) return false

	if (isApolloError(error)) {
		if (error.networkError) {
			const { message } = error.networkError
			return IGNORED_APOLLO_NETWORK_ERRORS.includes(message)
		}
	}

	return false
}

const MyFallbackComponent: React.FunctionComponent<FallbackProps> = ({
	componentStack,
	error,
}) => {
	const [isExpanded, setIsExpanded] = useState(false)
	return (
		<SafeAreaView>
			<View sx={{ p: 3 }}>
				{isErrorExpected(error) ? (
					<Text>An error occured.</Text>
				) : (
					<Text>⚠ Oops! An unexpected error occured.</Text>
				)}
				<Text sx={{ mb: 2 }}>Error: {error?.message || "unknown error"}</Text>
				<ButtonOutline
					onPress={() => setIsExpanded((s) => !s)}
					title={isExpanded ? "Hide Details" : "Show Details"}
				/>
				{isExpanded && (
					<View sx={{ pt: 3 }}>
						<Text>Components:</Text>
						<Text sx={{ mb: 3 }}>{componentStack}</Text>
						<Text>Stacktrace:</Text>
						<Text>{error?.stack}</Text>
					</View>
				)}
			</View>
		</SafeAreaView>
	)
}

export const ErrorBoundary: React.FunctionComponent = (props) => (
	<ReactErrorBoundary
		onError={myErrorHandler}
		FallbackComponent={MyFallbackComponent}
		{...props}
	/>
)

export default ErrorBoundary
