import { ApolloProvider } from "@apollo/react-hooks"
import { ThemeProvider } from "@emotion/react"
import * as Sentry from "@sentry/browser"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { createHttpLink } from "apollo-link-http"
import fetch from "isomorphic-unfetch"
import React from "react"
import { HistoryEntries } from "./hooks/useHistoryEntries"
import { SessionProvider, useSession } from "./hooks/useSession"
import { Router } from "./routing/Router"
import ErrorReporter from "./screens/ErrorReporter"
import Routes from "./screens/Routes"
import { theme } from "./theme"
import { ScrollContextProvider } from "./util/ScrollContextHelper"
import { KeepAwake } from "./components/KeepAwake"

Sentry.init({
	environment: process.env.SENTRY_ENVIRONMENT,
	dsn: process.env.SENTRY_PUBLIC_DSN,
	release: process.env.SENTRY_RELEASE,
	ignoreErrors: [/^cancelled$/],
})

/** @see https://github.com/apollographql/apollo-link/issues/539#issuecomment-479724987 */
const customFetch = (input: RequestInfo, init: RequestInit | undefined) => {
	const isGet = !init || init.method === "GET"
	const initWithHeadersFix = isGet ? { ...init, headers: {} } : init
	return fetch(input, initWithHeadersFix)
}

const AppWithApollo = ({ uri }: { uri: string }) => {
	const session = useSession()

	const client = React.useMemo(() => {
		const httpLink = createHttpLink({
			uri,
			...(session.authenticationStrategy === "cookies" && {
				useGETForQueries: true,
				fetch: customFetch,
				credentials: "same-origin",
			}),
		})

		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					...(session.authenticationStrategy === "bearer" && {
						Authorization: session.sessionToken
							? `Bearer ${session.sessionToken}`
							: "",
					}),
				},
			}
		})

		const errorLink = onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach((error) => {
					const { message, locations, path } = error
					// eslint-disable-next-line no-console
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
					Sentry.captureException(error)
				})

			if (networkError) {
				// eslint-disable-next-line no-console
				console.log(`[Network error]: ${networkError}`)
				Sentry.captureException(networkError)
			}
		})

		const link = ApolloLink.from([errorLink, authLink, httpLink])

		return new ApolloClient({
			link,
			cache: new InMemoryCache(),
		})
	}, [session.authenticationStrategy, session.sessionToken, uri])

	return (
		<ThemeProvider theme={theme}>
			{__DEV__ && <KeepAwake />}
			<ApolloProvider client={client}>
				<Router>
					<HistoryEntries>
						<ScrollContextProvider>
							<Routes />
						</ScrollContextProvider>
					</HistoryEntries>
				</Router>
			</ApolloProvider>
		</ThemeProvider>
	)
}

export default function AppWithSession({
	auth,
	uri,
}: {
	auth: "cookies" | "bearer"
	uri: string
}) {
	return (
		<ErrorReporter>
			<SessionProvider authenticationStrategy={auth}>
				<AppWithApollo uri={uri} />
			</SessionProvider>
		</ErrorReporter>
	)
}
