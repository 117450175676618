import React, { useState } from "react"
import { TextInput as RNTextInput } from "./Styled"
import { Platform } from "react-native"

const TextInput = React.forwardRef<
	typeof RNTextInput,
	React.ComponentProps<typeof RNTextInput>
>((props, ref) => {
	const [isFocused, setIsFocused] = useState(false)

	return (
		<RNTextInput
			{...props}
			// @ts-ignore ???
			ref={ref}
			sx={{
				flexGrow: 1,
				p: 2,
				borderStyle: "solid",
				borderColor: isFocused ? "primary" : "gray",
				borderWidth: 1,
				borderBottomWidth: 1,
				fontSize: 16,
				color: "text",
				...(Platform.OS === "web" && { outline: "none" }),
				...props.sx,
			}}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		/>
	)
})
export default TextInput
