import styled from "@emotion/native"
import css, { SystemStyleObject } from "@styled-system/css"

interface StyleProps {
	sx?: SystemStyleObject
	ref?: any // bleh
}

export const View = styled.View<StyleProps>((props) => css(props.sx))
export const SafeAreaView = styled.SafeAreaView<StyleProps>((props) =>
	css(props.sx)
)
export const Button = styled.Button<StyleProps>((props) => css(props.sx))
export const TextInput = styled.TextInput<StyleProps>((props) => css(props.sx))
export const Text = styled.Text<StyleProps>((props) => css(props.sx))
export const TouchableOpacity = styled.TouchableOpacity<StyleProps>((props) =>
	css(props.sx)
)
