// TODO... do this server side?

// const awsS3BucketCdn = process.env.AWS_S3_BUCKET_CDN
// TODO do more of this server-side
const awsS3BucketCdn = "d1guaiwt3zpbgc.cloudfront.net"
const imageCdnHost = "https://res.cloudinary.com" // state.config.imageCdnHost,

export const imageUrl = ({
	image,
	width,
}: {
	image: { s3Key: string }
	width?: number
}) => {
	const cdnUrl = `https://${awsS3BucketCdn}/${image.s3Key}`
	const transformations = [
		"f_auto",
		"c_thumb",
		// first frame for animated gifs
		"pg_1",
	]
	if (width) transformations.push(`w_${Math.round(width)}`)
	const transformationString = transformations.join(",")

	return `${imageCdnHost}/braise/image/fetch/${transformationString}/${cdnUrl}`
}
