import React, { forwardRef, useMemo, useCallback } from "react"
import { GestureResponderEvent } from "react-native"
import {
	BrowserRouter,
	Link as RRLink,
	LinkProps,
	Redirect,
	Route,
	StaticRouter,
	Switch,
} from "react-router-dom"
import {
	TouchableOpacity,
	TouchableOpacityProps,
} from "../components/Scrollable"

export { Redirect, Route, Switch, RRLink }

function isModifiedEvent(event: any) {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

interface LinkComponentProps {
	navigate: () => void
	target?: string
}

interface OnPressProps {
	onPress?: (gestureEvent: GestureResponderEvent) => void
}

/**
 * references:
 * - https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 */
function useLinkProps<T>({
	navigate,
	onPress: _onPress,
	...rest
}: T & LinkComponentProps & OnPressProps) {
	const { target } = rest

	const onPress = useCallback(
		(gestureEvent: GestureResponderEvent) => {
			try {
				if (_onPress) {
					_onPress(gestureEvent)
				}
			} catch (ex) {
				gestureEvent.preventDefault()
				throw ex
			}

			if (gestureEvent.isDefaultPrevented()) return

			// so filthy! haha
			const clickEvent = window.event

			if (
				!clickEvent ||
				(!clickEvent.defaultPrevented &&
				(!target || target === "_self") && // let browser handle "target=_blank" etc.
					!isModifiedEvent(clickEvent))
			) {
				navigate()
				gestureEvent.preventDefault()
				// rnw cancels the clickEvent
			}
		},
		[_onPress, navigate, target]
	)

	const props = useMemo(() => {
		return { ...rest, onPress, accessibilityRole: "link" }
	}, [onPress, rest])

	return props
}

const LinkAnchor = forwardRef<
	TouchableOpacity,
	LinkComponentProps & TouchableOpacityProps
>((props, forwardedRef) => {
	const linkProps = useLinkProps(props)
	// @ts-ignore
	return <TouchableOpacity {...linkProps} ref={forwardedRef} />
})

export const Link = ({ ...rest }: LinkProps) => {
	return <RRLink component={LinkAnchor} {...rest} />
}

// @ts-ignore
export const Router = process.browser ? BrowserRouter : StaticRouter
