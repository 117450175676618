import React from "react"
import { Platform } from "react-native"
import { useHistory } from "react-router"
import { NavLink, NavLinkCircle, NavLinkPrimary } from "../components/NavLink"
import { SafeAreaView, View } from "../components/Styled"
import { useHistoryEntries } from "../hooks/useHistoryEntries"
import { useViewer } from "../hooks/useViewer"
import {
	bookmarkNewPath,
	friendsPath,
	loginPath,
	rootPath,
	searchPath,
	settingsPath,
} from "../routing/paths"
import * as theme from "../theme"

const NavLinkGroup = ({ children }: { children?: React.ReactNode }) => (
	<View
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			flexDirection: "row",
			paddingLeft: 1,
			paddingRight: 1,
		}}
	>
		{children}
	</View>
)

const Container: React.FunctionComponent = (props) => (
	<>
		<SafeAreaView
			sx={{
				backgroundColor: "background",
				borderBottomWidth: 1,
				borderColor: "silver",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				paddingLeft: 1,
				paddingRight: 1,
				...(Platform.OS === "web" && {
					zIndex: 1,
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
				}),
			}}
			{...props}
		/>
		{Platform.OS === "web" && <View sx={{ height: 40 }} />}
	</>
)

export default function NavigationContainer() {
	const { viewer, loading } = useViewer()
	const history = useHistory()

	return (
		<Container>
			{viewer ? (
				<>
					<NavLinkGroup>
						<View>
							<NavLinkCircle
								accessibilityLabel="Profile Settings"
								onPress={() => {
									history.push(settingsPath())
								}}
								character={viewer.handle[0]}
								style={{ marginRight: theme.space[2] }}
							/>
						</View>

						<NavLink to={rootPath()}>Home</NavLink>
						<NavLink to={friendsPath()}>Friends</NavLink>
						<NavLink to={searchPath()}>Search</NavLink>
					</NavLinkGroup>
					<NavLinkGroup>
						<NavLinkPrimary
							to={bookmarkNewPath()}
							testID="new-bookmark-button"
							title="+ Save Recipe"
							smallTitle="+ Save"
							accessibilityLabel="Save recipe"
						/>
					</NavLinkGroup>
				</>
			) : loading ? (
				<NavLinkGroup>
					<View sx={{ height: 41 }} />
				</NavLinkGroup>
			) : (
				<React.Fragment>
					<NavLinkGroup>
						<NavLink to={rootPath()}>Home</NavLink>
						<NavLink to={searchPath()}>Search</NavLink>
					</NavLinkGroup>
					<NavLink to={loginPath()}>Sign in</NavLink>
				</React.Fragment>
			)}
		</Container>
	)
}

export function NavigationBack() {
	const history = useHistory()
	const historyEntries = useHistoryEntries()
	const canGoBack = historyEntries.index > 0
	const handleBack = () => {
		canGoBack ? history.goBack() : history.push(rootPath())
	}

	return (
		<Container>
			<NavLinkGroup>
				<NavLinkCircle
					accessibilityLabel="Go back"
					onPress={handleBack}
					character="←"
					style={{ marginRight: theme.space[2] }}
				/>
				<NavLink to={rootPath()}>Home</NavLink>
			</NavLinkGroup>
		</Container>
	)
}
