import React, { useCallback, useRef, useContext, forwardRef } from "react"
import { TouchableWithoutFeedbackProps } from "react-native"
import { TouchableOpacity as RNTouchableOpacity } from "./Styled"

type RNTouchableOpacityProps = React.ComponentProps<typeof RNTouchableOpacity>

type CancelPressProps = Pick<
	TouchableWithoutFeedbackProps,
	"onPressIn" | "onPressOut" | "onPress"
>

const ScrollContext = React.createContext(false)

export const Scrollable: React.FC = (props) => (
	<ScrollContext.Provider value={true} {...props} />
)

export const useScrollable = () => {
	const scrollable = useContext(ScrollContext)
	return scrollable
}

/**
 * hack to prevent touches from registering if they moved too much
 *
 * https://github.com/necolas/react-native-web/issues/1219#issuecomment-537724507
 */
function useScrollablePressProps<T>(
	{
		onPressIn: _onPressIn,
		onPressOut: _onPressOut,
		onPress: _onPress,
		...props
	}: CancelPressProps & T,
	threshold: number = 4
) {
	const pressInPagePointRef = useRef({ x: 0, y: 0 })

	const onPressIn = useCallback<NonNullable<CancelPressProps["onPressIn"]>>(
		(e) => {
			pressInPagePointRef.current = {
				x: e.nativeEvent.pageX,
				y: e.nativeEvent.pageY,
			}

			if (_onPressIn) _onPressIn(e)
		},
		[_onPressIn]
	)

	const onPressOut = useCallback<NonNullable<CancelPressProps["onPressOut"]>>(
		(e) => {
			if (_onPressOut) _onPressOut(e)

			const [x, y] = [e.nativeEvent.pageX, e.nativeEvent.pageY]

			if (
				Math.abs(pressInPagePointRef.current.x - x) > threshold ||
				Math.abs(pressInPagePointRef.current.y - y) > threshold
			) {
				// causes the following error in console:
				//
				// > [Intervention] Ignored attempt to cancel a touchend event with cancelable=false,
				// > for example because scrolling is in progress and cannot be interrupted
				//
				// but checking if it's cancelable will stop this from working
				e.preventDefault()
			}
		},
		[_onPressOut, threshold]
	)

	const onPress = useCallback<NonNullable<CancelPressProps["onPress"]>>(
		(e) => {
			if (e.isDefaultPrevented()) return
			if (_onPress) _onPress(e)
		},
		[_onPress]
	)

	return {
		onPress,
		onPressIn,
		onPressOut,
		...props,
	}
}

const ScrollableTouchableOpacity = React.forwardRef<
	typeof RNTouchableOpacity,
	RNTouchableOpacityProps
>((props, ref) => {
	const pressProps = useScrollablePressProps(props)
	// @ts-ignore
	return <RNTouchableOpacity ref={ref} {...pressProps} />
})

export const TouchableOpacity = forwardRef<
	typeof RNTouchableOpacity,
	RNTouchableOpacityProps
>((props: RNTouchableOpacityProps, ref) => {
	const isScrollable = useScrollable()
	return isScrollable ? (
		<ScrollableTouchableOpacity {...props} ref={ref} />
	) : (
		// @ts-ignore
		<RNTouchableOpacity {...props} ref={ref} />
	)
})
export type TouchableOpacity = typeof TouchableOpacity

export type TouchableOpacityProps = RNTouchableOpacityProps
