import React, { createContext, useState, useEffect, useContext } from "react"
import { useHistory } from "react-router"

const historyEntriesContext = createContext(
	null as null | {
		entries: any[]
		index: number
	}
)
const { Provider } = historyEntriesContext

/**
 * naive version of
 * https://github.com/ytase/browser-history-with-entries/blob/master/module/history.js
 */
export const HistoryEntries = ({ children }: { children: React.ReactNode }) => {
	const history = useHistory()
	const [state, setState] = useState(() => {
		return {
			entries: [history.location],
			index: 0,
		}
	})

	useEffect(() => {
		const unlisten = history.listen((newLocation, action) => {
			setState((state) => {
				let { entries, index } = state

				switch (action) {
					case "POP":
						index = entries.findIndex(
							(location) => location.key === newLocation.key
						)
						break

					case "REPLACE":
						entries[index] = { ...newLocation }
						break

					case "PUSH":
						entries = [...entries.slice(0, index + 1), newLocation]
						index += 1
						break
				}

				return { entries, index }
			})
		})

		return () => unlisten()
	}, [history])

	return <Provider value={state}>{children}</Provider>
}

export function useHistoryEntries() {
	const value = useContext(historyEntriesContext)
	if (!value)
		throw new Error(
			"useHistoryEntries must be called within a <HistoryEntries>"
		)
	return value
}
