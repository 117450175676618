import React, { createContext, useContext, useMemo, useEffect } from "react"
import usePersistedState from "./usePersistedState"
import { storeToken, clearToken } from "../util/keychain"

type SessionContextType =
	| {
			authenticationStrategy: "cookies"
			sessionToken?: undefined
			setSessionToken?: undefined
	  }
	| {
			authenticationStrategy: "bearer"
			sessionToken: string | null
			setSessionToken: (sessionToken: string | null) => void
	  }

const SessionContext = createContext<SessionContextType | undefined>(undefined)
const { Provider } = SessionContext

export function SessionProvider({
	authenticationStrategy,
	children,
}: {
	authenticationStrategy: "cookies" | "bearer"
	children: React.ReactNode
}) {
	const [sessionToken, setSessionToken] = usePersistedState("bSessionId", null)

	useEffect(() => {
		if (sessionToken) {
			storeToken(sessionToken)
		} else {
			clearToken()
		}
	}, [sessionToken])

	const value = useMemo((): SessionContextType => {
		switch (authenticationStrategy) {
			case "bearer":
				return {
					authenticationStrategy: "bearer",
					sessionToken,
					setSessionToken,
				}
			case "cookies":
				return {
					authenticationStrategy: "cookies",
				}
			default:
				throw new Error(`invariant: unexpected auth ${authenticationStrategy}`)
		}
	}, [authenticationStrategy, sessionToken, setSessionToken])

	return <Provider value={value}>{children}</Provider>
}

export function useSession() {
	const ctx = useContext(SessionContext)
	if (!ctx) throw new Error("useSession must be used within a SessionProvider")
	return ctx
}
