import React, { useEffect } from "react"
import { Text, TextStyle, StyleSheet, SafeAreaView, View } from "react-native"
import { colors, space } from "../theme"
import { ApolloError } from "apollo-client"
import * as Sentry from "@sentry/browser"

export default function QueryError({ error }: { error: ApolloError }) {
	useEffect(() => {
		Sentry.captureException(error)
	}, [error])

	return (
		<SafeAreaView>
			<View style={styles.Container}>
				<Text style={styles.QueryErrorTitle}>Oops 😬</Text>
				<Text style={styles.QueryError}>
					An error you probably cannot fix occured.
				</Text>
				<Text style={styles.QueryError}>{error.message}</Text>
			</View>
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	Container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		paddingVertical: space[3],
	},
	QueryErrorTitle: {
		color: colors.red,
		marginBottom: space[3],
	} as TextStyle,
	QueryError: { color: colors.red } as TextStyle,
})
