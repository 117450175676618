import { useQuery } from "@apollo/react-hooks"
import { Viewer } from "../generated/types/Viewer"
import viewerQuery from "../queries/viewer.query"
import { useEffect, useMemo } from "react"
import * as Sentry from "@sentry/browser"

export const useViewer = () => {
	const viewerRequest = useQuery<Viewer>(viewerQuery, {
		fetchPolicy: "cache-first",
	})

	const { data, error, loading } = viewerRequest

	if (error) throw error

	useEffect(() => {
		if (!data || !data.viewer) return
		const { viewer } = data

		Sentry.configureScope((scope) => {
			scope.setUser({
				email: viewer.email,
				username: viewer.handle,
			})
		})
	}, [data])

	const value = useMemo(() => ({ viewer: data && data.viewer, loading }), [
		data,
		loading,
	])

	return value
}
