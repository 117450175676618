import React from "react"
import { View } from "./Styled"

export const Container: React.FunctionComponent = (props) => (
	<View
		{...props}
		sx={{
			maxWidth: "container",
			mx: "auto",
			width: "100%",
		}}
	/>
)
