// https://github.com/devhubapp/devhub/blob/master/packages/components/src/hooks/use-dimensions.ts

import { useEffect, useState } from "react"
import { Dimensions, Platform } from "react-native"

export function useDimensions() {
	const [dimensions, setDimensions] = useState({
		screen: Dimensions.get("screen"),
		window: Dimensions.get("window"),
	})

	useEffect(() => {
		Dimensions.addEventListener("change", setDimensions)
		return () => Dimensions.removeEventListener("change", setDimensions)
	}, [])

	return dimensions
}

export function getDimensions() {
	if (Platform.OS === "web") {
		return {
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight,
		}
	} else {
		const { width, height } = Dimensions.get("window")
		return { width, height }
	}
}

// TODO: debounce?
export function useDimensionsBasic() {
	const [dimensions, setDimensions] = useState(getDimensions())
	const updateDimensions = () => setDimensions(getDimensions())

	useEffect(() => {
		Dimensions.addEventListener("change", updateDimensions)
		return () => Dimensions.removeEventListener("change", updateDimensions)
	}, [])

	return dimensions
}
