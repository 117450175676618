import gql from "graphql-tag"

export const urlFragment = gql`
	fragment Url on BookmarkUrl {
		id
		url
		domain
		pathname
		title
	}
`
